import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout/Layout"
import Footer from "../components/footer/Footer.jsx"
import Section from "../components/section/Section"

const Page = ({ data }) => {
  const { page } = data

  useEffect(() => {
    document.documentElement.style.setProperty("--header-bg", "#395A48")
    document.documentElement.style.setProperty("--header-text", "#90DEFF")
  }, [])

  return (
    <Layout
      title={page.metaTitle || page.heading}
      description={page.metaDescription}
    >
      <Section theme={["#395A48", "#90DEFF"]}>
        <div className="px-ogs py-16 bg-header-bg text-header-text">
          {page.heading && (
            <Fade triggerOnce duration={750}>
              <h1 className="text-8xl md:text-12xl 2xl:text-15xl font-fletcha tracking-tightest leading-none">
                {page.heading}
              </h1>
            </Fade>
          )}
          <div className="grid grid-cols-8 gap-x-gs">
            <div className="col-span-full lg:col-span-4">
              <div className="lg:flex lg:flex-col lg:h-full">
                {page.image && page.image.length > 0 && (
                  <Fade triggerOnce duration={500}>
                    <div className="w-full lg:max-w-500 xl:max-w-745 my-10 lg:mt-20">
                      <GatsbyImage
                        image={getImage(page.image[0].localFile)}
                        alt={page.image[0].title}
                        className="h-full w-full object-cover"
                      />
                    </div>
                  </Fade>
                )}
                {/* <Fade
                triggerOnce
                duration={500}
                className="mt-12 hidden lg:flex absolute bottom-0"
              >
                <Link
                  to="/"
                  className="Link Link--alt Link--underline text-xl lg:text-3xl font-tomato tracking-tightest cursor-pointer"
                >
                  Back to home
                </Link>
              </Fade> */}
              </div>
            </div>
            {page.body && (
              <Fade
                triggerOnce
                duration={500}
                className="lg:mt-20 col-start-1 lg:col-start-5 col-end-9"
              >
                <div
                  className="text-sm font-tomato rte--body rte"
                  dangerouslySetInnerHTML={{ __html: page.body }}
                ></div>
              </Fade>
            )}
            <Fade
              triggerOnce
              duration={500}
              className="flex lg:hidden mt-12 lg:mt-auto w-full col-span-full"
            >
              {/* <Link
              to="/"
              className="Link Link--alt Link--underline text-xl lg:text-3xl font-tomato tracking-tightest cursor-pointer"
            >
              Back to home
            </Link> */}
            </Fade>
          </div>
        </div>
      </Section>
      <Section theme={["#1B4555", "#F8D990"]}>
        <div className="bg-teal py-8 xl:py-20 text-warm-yellow relative">
          <div className="grid grid-cols-8 md:mb-20 px-ogs">
            <div className="col-span-full lg:col-span-5">
              {page.featuresHeading && (
                <h2 className="text-7xlb lg:text-8xl tracking-tightest leading-tightest font-fletcha">
                  {page.featuresHeading}
                </h2>
              )}
            </div>
            {page.featuresText && (
              <div
                className={`lg:col-span-3 mt-10 lg:mt-0 col-start-1 col-span-full ${
                  page.featuresText ? "lg:border-l border-warm-yellow" : ""
                } lg:-ml-gs-1/2 lg:pl-gs-1/2`}
              >
                <p>{page.featuresText}</p>
              </div>
            )}
          </div>
          <div className="grid xl:grid-cols-2 x-axis-border y-axis-border">
            {page.productSecondary &&
              page.productSecondary.map(product => (
                <div className="mt-gs pb-gs" key={product.id}>
                  <div className="px-ogs h-full md:space-y-12 space-y-8">
                    {product.image[0] && (
                      <div className="featured-image-ratio mt-14 md:mt-0">
                        <GatsbyImage
                          image={getImage(product.image[0].localFile)}
                          alt={product.image[0].title}
                          className="w-full h-full object-fill"
                        />
                      </div>
                    )}
                    {product.heading && (
                      <h2 className="text-6xl xl:text-6xlb font-fletcha tracking-tightest leading-tightest">
                        {product.heading}
                      </h2>
                    )}
                    {product.body && (
                      <div className="mb-gs">
                        <div
                          className="text-sm font-tomato rte--body rte mb-gs"
                          dangerouslySetInnerHTML={{ __html: product.body }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Section>

      <Footer />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageQuery($uri: String) {
    page: craftPagesPagesEntry(uri: { eq: $uri }) {
      uri
      title
      ... on Craft_pages_pages_Entry {
        metaTitle
        metaDescription
        heading
        body
        image {
          ... on Craft_images_Asset {
            id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
      featuresHeading
      featuresText
      heading
      image {
        ... on Craft_images_Asset {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
        id
        title
        ... on Craft_icons_Asset {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
      productSecondary {
        ... on Craft_productSecondary_items_BlockType {
          id
          heading
          body
          image {
            url
            ... on Craft_images_Asset {
              id
              url
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.33
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
            ... on Craft_icons_Asset {
              id
              url
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.33
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
